@import '../../styles/variables.styl';

.burger {
  position: absolute;
  top: 20px;
  right: -4px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: -40px;
    right: -36px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $dark;
    will-change: width, height;
    transform: translate3d(0, 0, 0) scale(3);
    transition: transform .5s ease-in-out,
      width .5s ease-in-out,
      height .5s ease-in-out;
  }

  &_active {
    &:after {
      width: 100vmax;
      height: 100vmax;
    }
  }

  & img {
    padding: 4px;
    display: block;
    cursor: pointer;
  }
}
