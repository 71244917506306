@import '../../styles/variables.styl';

.breadcrumbs {
  padding: 30px 0 20px;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;

  & span {
    color: $blue;
  }

  @media $mqMobile {
    display: none;
  }
}
