@import '../../styles/variables.styl';

.intro {
  position: relative;
  padding-top: 124px;
  height: 480px;

  & svg {
    position: absolute;
    z-index: -1;
  }

  @media $mqDesktop {
    & svg {
      top: 64px;
      left: 8px;
      width: 1009px;
      height: 416px;
    }
  }

  @media $mqTablet {
    padding-top: 82px;
    height: 340px;

    & svg {
      top: 20px;
      left: 110px;
      width: 100%;
    }
  }

  @media $mqMobile {
    padding-top: 53px;
    padding-right: 15px;
    height: 322px;

    & svg {
      top: 161px;
      left: auto;
      right: 10px;
      width: 290px;
      height: 154px;
    }
  }
}
