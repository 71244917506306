@import '../../styles/variables.styl';

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  transition: opacity 0.75s ease-in-out,
              visibility 0.25s ease-in-out;

  &_active {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }

  &__close {
    position: absolute;
    top: 19px;
    right: 16px;

    & img {
      padding: 4px;
      display: block;
      cursor: pointer;
    }
  }
}
