@import '../../styles/variables.styl';

.customers-item {
  position: relative;
  margin-right: 30px;
  padding-bottom: 59px;
  width: 300px;
  vertical-align: top;
  display: inline-block;

  &:nth-child(3n+3) {
    margin: 0;
  }

  &_theme_main {
    &:nth-child(3) {
      padding-bottom: 0px;
    }
  }

  &_theme_inside {

  }

  &__photo {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background: #BEBFD3;
  }

  &__logo {
    position: absolute;
    top: 160px;
    right: 30px;
  }

  &__name {
    margin-top: 23px;
    font-size: 18px;
    color: $black;
    line-height: 21px;
    font-weight: 700;
    transition: color .25s ease-in-out;
  }

  &__desc {
    margin-top: 12px;
    font-size: 14px;
    color: $grey;
    line-height: 17px;
    transition: color .25s ease-in-out;
  }

  &__url {
    position: relative;
    margin-top: 14px;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;

    & a {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $blue;
        opacity: 0.5;
        transition: background .25s ease-in-out;
      }

      &:hover {
        &:after {
          background: $red;
        }
      }
    }

    & span {
      position: absolute;
      top: -8px;
      right: -16px;

      & img {
        width: 11px;
        height: 7px;
      }
    }
  }

  @media $mqTablet {
    margin: 10px;
    margin-bottom: 49px;
    width: 100%;
    max-width: 300px;
    text-align: left;

    &:nth-child(3n+3) {
      margin: 10px;
      margin-bottom: 49px;
    }

    &__photo {
      width: 100%;
      height: 140px;
      border-radius: 12px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__logo {
      top: 100px;
      right: 10px;
    }

    &__name {
      margin-top: 23px;
    }
  }

  @media $mqMobile {
    margin-right: 0;
    padding-bottom: 49px;
    width: 100%;

    &:nth-child(-n+3) {
      display: block;
    }

    &:nth-child(3n+3) {
      margin-bottom: 0;
    }

    &__photo {
      width: 100%;
      height: 140px;
      border-radius: 12px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__logo {
      top: 100px;
      right: 10px;
    }

    &__name {
      margin-top: 23px;
    }

    &__url {
      margin-top: 12px;
    }
  }
}
