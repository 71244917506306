@import '../../styles/variables.styl';

.header {
  padding-top: 39px;

  @media $mqMobile {
    position: relative;
    padding-top: 22px;
    background: #fff;

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 1px 5px #ccc;
      padding: 22px 20px 16px;
      z-index: 1;

      & .burger {
        right: 16px;
      }
    }
  }
}
