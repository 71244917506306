@import '../../styles/variables.styl';

.up {
  margin-top: 29px;
  text-align: center;

  & a {
    padding: 5px 10px;
    display: inline-block;
    font-size: 18px;
    color: $light_blue;
    line-height: 27px;

    &:hover {
      color: $red;

      & svg path {
        fill: $red;
      }
    }
  }

  & span {
    display: inline-block;
    vertical-align: middle;
  }

  & .up-svg {
    margin-left: 9px;
    width: 16px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;

    & svg path {
      fill: $light_blue
      transition: fill .25s ease-in-out;
    }
  }
}
