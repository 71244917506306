@import '../../styles/variables.styl';

.reviews {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 38px;
      right: 104px;
      width: 471px;
      height: 193px;
    }
  }

  @media $mqTablet {
    &__intro {
      & .intro-svg {
        top: 38px;
        right: 0;
      }
    }
  }

  @media $mqMobile {
    &__intro {
      & .intro-svg {
        top: 27px;
        right: 0;
        width: 104px;
        height: 102px;
      }
    }
  }
}
