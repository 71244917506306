@import '../node_modules/minireset.css/minireset.css';
@import './styles/variables.styl';

body {
  margin: 0;
  padding: 0;
  font-family: $fonts;
  font-weight: 400;
}

#root {
  overflow: hidden;
}

.content {
  padding: 50px 0 100px 0;

  & .intro-svg svg {
    overflow: visible;
    z-index: 2;
  }

  & .title {
    padding: 0 0 54px;
  }

  & .text {
    position: relative;
    margin-top: 120px;
    padding-top: 54px;
    padding-bottom: 50px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -9999em;
      bottom: 0;
      left: -9999em;
      z-index: -1;
      background-color: #f5f5f5;
    }
  }
}

a {
  color: $blue;
  text-decoration: none;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $red;
  }
}

@media $mqTablet {
  & .content {
    padding: 35px 0 75px;
  }
}

@media $mqMobile {
  & .content {
    padding: 20px 0 50px 0;

    & .title {
      padding: 33px 0 32px;
    }

    & .text {
      margin-top: 60px;
      padding-top: 32px;
      padding-bottom: 50px;
    }
  }
}
