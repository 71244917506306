@import '../../styles/variables.styl';

.licenses-item {
  margin-right: 30px;
  margin-bottom: 30px;
  width: 300px;
  vertical-align: top;
  display: inline-block;

  &__content {
    padding: 40px 40px 0 40px;
    height: 350px;
    text-align: center;
    vertical-align: top;
    background: $white;
    border-radius: 20px;
    cursor: pointer;
  }

  &:hover {
    & .licenses-item__name {
      color: $red;
    }
  }

  &__image {
    width: 220px;
    height: 190px;
    overflow: hidden;
    border-radius: 16px;
  }

  &__name {
    margin-top: 22px;
    color: $blue;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    transition: color .25s ease-in-out;
  }

  &:nth-child(3n+3) {
    margin: 0;
  }

  @media $mqTablet {
    margin-right: 20px;
    margin-bottom: 20px;
    width: auto;
    display: block;

    &__content {
      padding: 20px 20px 20px 20px;
      height: 100px;
      border-radius: 12px;
      display: inline-block;
    }

    &:nth-child(3n+3) {
      margin-right: 20px;
      margin-bottom: 20px;
    }

    &__image {
      width: 60px;
      height: 60px;
      vertical-align: middle;
      display: inline-block;
    }

    &__name {
      margin: 0 0 0 15px;
      width: calc(100% - 60px - 15px);
      font-size: 16px;
      line-height: 118%;
      text-align: left;
      vertical-align: middle;
      display: inline-block;
    }
  }

  @media $mqMobile {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    display: block;

    &__content {
      padding: 20px 20px 20px 20px;
      height: 100px;
      border-radius: 12px;
    }
    &:nth-child(3n+3) {
      margin-bottom: 20px;
    }

    &__image {
      width: 60px;
      height: 60px;
      vertical-align: middle;
      display: inline-block;
      border-radius: 8px;
    }

    &__name {
      margin: 0 0 0 15px;
      width: calc(100% - 60px - 15px);
      font-size: 16px;
      line-height: 118%;
      text-align: left;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
