@import '../../styles/variables.styl';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out,
              visibility 0.25s ease-in-out;

  &_active {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease-in-out,
                visibility 0.25s ease-in-out;
  }

  &__blackout {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
  }

  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 40px 40px 40px;
    width: 510px;
    border-radius: 12px;
    background: $white;
    z-index: 1;
  }

  &__title {
    font-size: 22px;
    color: $black;
    line-height: 24px;
    letter-spacing: 0.007em;
  }

  &__content {
    padding-top: 16px;

    & img {
      margin: 0 auto;
      display: block;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 5px;
    cursor: pointer;

    & svg {
      width: 20px;
      height: 20px;
      display: block;
    }

    & svg path {
      transition: fill 0.25s ease-in-out;
    }

    &:hover {
      & svg path {
        fill: $red;
      }
    }
  }

  @media $mqMobile {
    &__inner {
      padding: 22px 20px 20px 20px;
      width: calc(100% - 40px);
      min-width: 280px;
      max-width: 510px;
      border-radius: 12px;
      background: $white;
    }

    &__title {
      font-size: 20px;
    }

    &__content {
      padding-top: 14px;
      padding-bottom: 30px;
    }

    &__close {
      top: 18px;
      right: 13px;

      & svg {
        transform: scale(0.8);
      }
    }
  }
}
