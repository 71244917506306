@import '../../styles/variables.styl';

.request {
  &__form-item {
    position: relative;

    & span {
      position: absolute;
      left: 0;
      bottom: 3px;
    }

    &:nth-child(3) {
      & span {
        bottom: -17px;
      }
    }
  }

  & input {
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 430px;
    height: 40px;
    font-family: $fonts;
    font-size: 16px;
    border: 0;
    border-radius: 6px;
    outline: none;
    background: $light_grey;
  }

  & input[name=captcha] {
    width: 340px;
    text-transform: uppercase;
  }

  & textarea {
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 430px;
    height: 100px;
    display: block;
    font-family: $fonts;
    font-size: 16px;
    border: 0;
    border-radius: 6px;
    resize: none;
    outline: none;
    background: $light_grey;
  }

  & span {
    padding: 0 20px;
  }

  &__error {
    padding: 0;
    display: inline-block;
    font-size: 12px;
    color: $red;
  }

  &__policy {
    margin: 10px 50px 0;
    font-size: 12px;
    text-align: center;
  }

  & button {
    padding: 11px 30px;
    width: 100%;
    min-width: 188px;
    display: inline-block;
    font-family: $fonts;
    font-size: 22px;
    color: $white;
    text-align: center;
    line-height: 32px;
    text-decoration: none;
    border-radius: 27px;
    background: $red;
    transition: background .25s ease-in-out;
    border: 0;
    outline: 0;
    cursor: pointer;

    &:hover {
      color: $white;
      background: $red_active;
    }
  }

  &::placeholder {
    color: $black;
    text-transform: none;
  }

  @media $mqMobile {
    &__policy {
      margin: 10px 20px 0;
      font-size: 12px;
      text-align: center;
    }
    &__error {
      font-size: 12px;
      line-height: 14px;
    }

    & input,
    & textarea {
      padding: 8px 18px;
      width: 100%;
      font-size: 16px;
    }

    & input[name=captcha] {
      width: 148px;
    }

    & button {
      margin: 0 auto;
      padding: 9px 0;
      width: 100%;
      min-width: 128px;
      font-size: 18px;
      display: block;
    }
  }
}
