@import '../../styles/variables.styl';

.menu {
  &_type_header {
    margin: 34px 0 0;
    padding: 3px 58px 0;
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;

    & .menu__item {
      padding: 0 10px;
      display: inline-block;

      & a {
        position: relative;
        color: $blue;
        font-size: 16px;
        text-decoration: none;
        transition: color 0.25s ease-in-out;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -1px;
          left: 0;
          width: auto;
          height: 3px;
          background: transparent;
          transition: background 0.25s ease-in-out;
        }
      }

      & a:hover,
      & a.active {
        color: $black;

        &:after {
          background: $red;
        }
      }
    }
  }

  &_type_burger {
    margin: 0;
    padding: 27px 40px;
    list-style-type: none;

    & .menu__item {
      margin-bottom: 19px;

      &:last-child {
        margin-bottom: 0;
      }

      & a {
        position: relative;
        display: inline-block;
        font-size: 18px;
        color: $white;
        font-weight: 400;
        text-decoration: none;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -1px;
          left: 0;
          width: auto;
          height: 3px;
          background: transparent;
          transition: background 0.25s ease-in-out;
        }
      }

      & a:hover,
      & a.active {
        &:after {
          background: $red;
        }
      }
    }
  }

  &_type_footer {
    & .menu__item {
      margin: 0 30px 0 0;
      display: inline-block;
      letter-spacing: 0.072em;

      &:last-child {
        margin-right: 0;
      }

      & a {
        position: relative;
        color: $light_blue;
        font-size: 16px;
        text-decoration: none;
        transition: color 0.25s ease-in-out;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -1px;
          left: 0;
          width: auto;
          height: 3px;
          background: transparent;
          transition: background 0.25s ease-in-out;
        }
      }

      & a:hover,
      & a.active {
        color: $white;

        &:after {
          background: $red;
        }
      }
    }
  }

  @media $mqTablet {
    &_type_header {
      margin: 0 0 0 15px;
      padding: 0;
    }
  }
}
