@import '../../styles/variables.styl';

.cookie {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out,
              visibility 0.25s ease-in-out;

  &_active {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease-in-out,
                visibility 0.25s ease-in-out;
  }

  &__inner {
    padding: 10px;
    font-size: 12px;
    color:  $grey;
    border-top: 1px solid $light_grey;
    background: $white;
  }

  &__content {
    padding: 15px 35px 15px 15px;

    & img {
      margin: 0 auto;
      display: block;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 5px;
    cursor: pointer;

    & svg {
      width: 20px;
      height: 20px;
      display: block;
    }

    & svg path {
      transition: fill 0.25s ease-in-out;
    }

    &:hover {
      & svg path {
        fill: $red;
      }
    }
  }

  @media $mqMobile {
    &__close {
      top: 18px;
      right: 13px;

      & svg {
        transform: scale(0.8);
      }
    }
  }
}
