// colors
$white = #fff;
$black = #000;
$blue = #3448c0;
$light_blue = #6A7FFF;
$red = #fa6e71;
$red_active = #fd4a4d;
$dark = #35365b;
$grey = rgba(0, 0, 0, 0.5);
$dark_grey = #232323;
$light_grey = #F5F5F5;
$white_50 = rgba(255, 255, 255, 0.5);

// fonts
$fonts = 'Circe', sans-serif;

// media queries
$desktop = 1024px;
$tablet = 768px;
$mobile = 320px;

$mqDesktop = '(min-width: $desktop)';
$mqTablet = '(min-width: $tablet) and (max-width: $desktop - 1px)';
$mqMobile = '(max-width: $tablet - 1px)';

@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe-light.woff');
  src: url('../fonts/circe-light.woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe-regular.woff');
  src: url('../fonts/circe-regular.woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe-bold.woff');
  src: url('../fonts/circe-bold.woff2');
  font-weight: 700;
  font-style: normal;
}
