@import '../../styles/variables.styl';

.none {
  &__image {
    position: relative;
    height: 434px;

    & .intro-svg {
      margin: 0 auto;
      padding: 30px 15px;
      width: 805px;
      height: 434px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media $mqTablet {
  }

  @media $mqMobile {
    &__image {
      margin: 43px 0;
      height: 341px;

      & .intro-svg {
        padding: 0;
        width: 332px;
        height: 341px;
      }
    }
  }
}
