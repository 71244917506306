@import '../../styles/variables.styl';

.customers {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 30px;
      right: 82px;
      width: 410px;
      height: 219px;
    }
  }

  @media $mqTablet {
    &__page {
      & .text {
        text-align: center;
      }
    }

    &__intro {
      & .intro-svg {
        top: 30px;
        right: 0;
      }
    }
  }

  @media $mqMobile {
    &__page {
      & .text {
        padding-top: 48px;
      }
    }

    &__intro {
      & .intro-svg {
        top: 27px;
        right: 0;
        width: 104px;
        height: 104px;
      }
    }
  }
}
