@import '../../styles/variables.styl';

.services {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 58px;
      right: 100px;
      width: 419px;
      height: 937px;
    }
  }

  &__page {
    & .text {
      padding-bottom: 13px;
    }

    & h3 {
      font-size: 30px;
      font-weight: 700;
    }
  }

  &__list {
    padding-top: 31px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;

    & .services__item {
      margin-right: 0;
      margin-bottom: 20px;
      width: auto;
      height: auto;
      display: block;
      align-self: flex-start;
    }
  }

  @media $mqTablet {
    &__intro {
      & .intro-svg {
        top: 58px;
        right: 0;
      }
    }
  }

  @media $mqMobile {
    &__page {
      & .text {
        padding-bottom: 10px;
      }

      & h3 {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__list {
      padding-top: 25px;
      padding-bottom: 50px;

      & .services__item {
        width: 100%;
      }
    }


    &__intro {
      & .intro-svg {
        top: auto;
        bottom: -64px;
        right: 0px;
        width: 104px;
        height: 133px;
      }
    }
  }
}
