@import '../../styles/variables.styl';

.next {
  margin-top: 120px;
  margin-bottom: 125px;
  padding-top: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 47px;

  & span {
    display: inline-block;
    vertical-align: middle;
  }

  & .arrow-svg {
    position: relative;
    width: 104px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    width: 101px;
    height: 32px;
    margin-left: 20px;
  }

  & svg {
    position: absolute;
    left: 50%;
    top: calc(50% + 3px);
    transform: translate(-50%, -50%);
  }

  & svg path {
    margin-left: 15px;
    stroke: $blue;
    transition: stroke .25s ease-out;
  }

  & a:hover {
    & svg path {
      stroke: $red;
    }
  }

  @media $mqMobile {
    margin-top: 60px;
    margin-bottom: 0;
    padding: 20px 0 0 0;
    display: inline-block;
    font-size: 26px;
    line-height: 32px;

    & .arrow-svg {
      margin-left: 5px;
    }

    & svg {
      height: 23px;
      width: 123px;
      left: 50%;
      top: calc(50% + 1px);
      transform: translate(-50%, -50%);
    }
  }
}
