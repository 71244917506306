@import '../../styles/variables.styl';

.licenses {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 8px;
      right: 75px;
      width: 226px;
      height: 241px;
    }
  }

  &__page {
    & .text {
      padding-top: 25px;
      padding-bottom: 100px;
    }
  }

  @media $mqTablet {
    &__intro {
      & .intro-svg {
        top: 8px;
        right: 0;
      }
    }
  }

  @media $mqMobile {
    &__page {
      & .title {
        padding-right: 105px;
      }

      & .text {
        padding-top: 36px;
        padding-bottom: 37px;
      }
    }

    &__intro {
      & .intro-svg {
        top: auto;
        bottom: -60px;
        right: -6px;
        width: 104px;
        height: 119px;
      }
    }
  }
}
