@import '../../styles/variables.styl';

.reviewsitem {
  position: relative;
  padding: 42px 40px 37px;
  margin-bottom: 29px;
  min-height: 270px;
  color: $black;
  border-radius: 20px;
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__content {
    margin-right: 45px;
    width: 590px;
  }

  &__desc {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 130%;
  }

  &__name {
    font-size: 22px;
    font-weight: 700;
    line-height: 130%;
  }

  &__image {
    position: absolute;
    right: 40px;
    top: 40px;
    bottom: 40px;
    width: 250px;
    height: 190px;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    & img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media $mqTablet {
    &__content {
      margin-right: 45px;
      width: calc(100% - 240px - 45px);
    }

    &__desc {
      margin-bottom: 10px;
      font-size: 14px;
    }

    &__name {
      font-size: 16px;
    }
  }

  @media $mqMobile {
    padding: 15px 20px 30px 20px;
    margin-bottom: 20px;
    flex-direction: column;

    &__content {
      margin: 0;
      width: 100%;
      order: 2;
    }

    &__desc {
      margin-bottom: 10px;
      font-size: 14px;
    }

    &__name {
      font-size: 16px;
    }

    &__image {
      margin-bottom: 26px;
      height: 140px;
      order: 1;

      position: relative;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
      width: 240px;
      border-radius: 16px;

      & img {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
      }
    }
  }
}
