@import '../../styles/variables.styl';

.logo {
  margin-top: 34px;
  display: inline-block;
  vertical-align: middle;

  & img {
    display: block;
  }

  &_type_white {
    margin: 0;
    margin-top: 25px;
  }

  @media $mqTablet {
    margin: 0;

    &_type_white {
      margin: 0;
      margin-top: 25px;
    }
  }

  @media $mqMobile {
    margin: -4px 0 0 -4px;

    & img {
      padding: 4px;
    }
  }
}
