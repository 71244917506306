@import '../../styles/variables.styl';

.title {
  margin: 0;
  width: 644px;
  font-family: $fonts;
  font-size: 40px;
  color: $black;
  line-height: 47px;
  font-weight: 700;

  &_color_white {
    width: 100%;
    padding: 0;
    color: $white;
    text-align: center;
  }

  &_color_black {
    width: 100%;
    padding: 0;
    color: $black;
    text-align: center;
  }

  @media $mqMobile {
    width: 100%;
    font-size: 26px;
    line-height: 31px;
    text-align: left;
  }
}
