@import '../../styles/variables.styl';

.card {
  display: block;
  height: 100%;
  background: $white;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    & .card__link {
      color: $red;

      & p {
        text-decoration-color: rgba(250, 110, 113, 0.5);
        transition: text-decoration-color .25s ease-in-out;
      }
    }
  }

  &__icon {
    position: relative;

    & img {
      display: block;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  &__link {
    padding: 0 30px;
    display: block;
    color: $blue;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    transition: color .25s ease-in-out;

    & p {
      text-decoration-line: underline;
      text-decoration-color: rgba(52, 72, 192, 0.5);
      transition: text-decoration-color .25s ease-in-out;
    }
  }

  &__text {
    margin-top: 18px;
    padding: 0 10px;
    color: $black;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &_type_services-main {
    & .card__icon {
      height: 251px;

      & img {
        padding-top: 20px;
      }
    }

    & .card__link {
      & p {
        font-size: 22px;
      }
    }
  }

  &_type_services-other {
    & .card__icon {
      height: 207px;

      & img {
        padding-top: 2px;
      }
    }

    & .card__link {
      & p {
        font-size: 22px;
      }
    }
  }

  &_type_services-min {
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 20px;

    & .card__icon {
      margin: 20px 0 20px 25px;
      width: 80px;
      height: 60px;

      & img {
        padding-top: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    & .card__link {
      padding: 0 30px 0 10px
      width: calc(100% - 60px);
      text-align: left;

      & p {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  &_type_advantages {
    & .card__icon {
      height: 155px;

      & img {
        padding-top: 0;
      }
    }
  }

  @media $mqTablet {
    display: flex;
    align-items: center;
    border-radius: 12px;

    & .card__icon {
      margin: 20px 0 20px 20px;
      width: 80px;
      height: 60px;

      & img {
        padding-top: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    & .card__link {
      padding: 0 20px 0 15px;
      width: calc(100% - 60px);
      text-align: left;

      & p {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &_type_advantages {
      & .card__icon {
        margin: 0;
        width: 60px;
        height: 60px;

        & img {
          padding: 0;
        }
      }

      & .card__icon_mobile {
        margin: 0;
      }

      & .card__text {
        margin: 0;
        padding: 3px 0 0 12px;
        width: calc(100% - 60px);
        text-align: left;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media $mqMobile {
    display: flex;
    align-items: center;
    border-radius: 12px;

    & .card__icon {
      margin: 20px 0 20px 20px;
      width: 80px;
      height: 60px;

      & img {
        padding-top: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    & .card__link {
      padding: 0 20px 0 7px;
      width: calc(100% - 60px);
      text-align: left;

      & p {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &_type_services-min {
      padding: 0;
      border-radius: 12px;

      & .card__link {
        padding: 0 15px 0 7px;
      }
    }

    &_type_advantages {
      & .card__icon {
        margin: 0;
        width: 60px;
        height: 60px;

        & img {
          padding: 0;
        }
      }

      & .card__icon_mobile {
        margin: 0;
      }

      & .card__text {
        margin: 0;
        padding: 3px 0 0 12px;
        width: calc(100% - 60px);
        text-align: left;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
