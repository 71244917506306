@import '../../styles/variables.styl';

.about {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 19px;
      right: 0px;
      width: 490px;
      height: 189px;
    }
  }

  &__container {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: 1245px;

    &:before,
    &:after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }
  }

  &__item {
    width: 50%;

    &:nth-child(2n+1) {
      margin-right: 60px;
      width: 500px;
      order: 1;
    }

    &:nth-child(2n) {
      width: 400px;
      order: 2;

      & img {
        display: block;
        margin-bottom: 23px;
      }
    }

    & p {
      padding-bottom: 25px;
      font-family: $fonts;
      font-size: 18px;
      line-height: 23px;
    }

    & ul {
      margin-top: -25px;
      font-family: $fonts;
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media $mqTablet {
    &__intro {
      & .intro-svg {
        top: 19px;
        right: 0;
      }
    }

    &__container {
      height: auto;
    }

    &__item {
      width: 100%;

      &:nth-child(2n+1) {
        margin-right: 0;
        width: 100%;
        order: 1;
      }

      &:nth-child(2n) {
        width: 100%;
        order: 1;

        & img {
          display: block;
          margin-bottom: 25px;
        }
      }
    }
  }

  @media $mqMobile {
    &__intro {
      & .intro-svg {
        top: 47px;
        right: 0;
        width: 129px;
        height: 108px;
      }
    }

    &__container {
      height: auto;
    }

    &__item {
      width: 100%;

      & p {
        padding-bottom: 20px;
        font-family: $fonts;
        font-size: 16px;
        line-height: 21px;
      }

      & ul {
        margin-top: -20px;
        font-family: $fonts;
        font-size: 16px;
        line-height: 21px;
      }

      &:nth-child(2n+1) {
        margin-right: 0;
        width: 100%;
        order: 1;
      }

      &:nth-child(2n) {
        width: 100%;
        order: 1;

        & img {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
  }
}
