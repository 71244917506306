@import '../../styles/variables.styl';

.waves {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -2;

  & canvas {
    display: block;
  }

  @media $mqMobile {
    & canvas {
      width: 100%;
      height: 60px;
    }
  }
}
