@import '../../styles/variables.styl';

.more {
  text-align: center;

  &__link {
    min-width: 188px;
    padding: 11px 30px;
    display: inline-block;
    background: $red;
    border-radius: 27px;
    font-size: 22px;
    color: $white;
    line-height: 32px;
    transition: background .25s ease-in-out;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $white;
      background: $red_active;
    }
  }

  @media $mqMobile {
    &__link {
      margin: 0 auto;
      padding: 9px 0;
      width: 100%;
      min-width: 128px;
      max-width: 280px;
      font-size: 18px;
      display: block;
    }
  }
}
