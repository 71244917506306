@import '../../styles/variables.styl';

.layout {
  margin: 0 auto;
  padding: 0 20px;
  width: 1000px;

  &_fixed {
    position: fixed;
  }

  @media $mqTablet {
    width: 100%;
  }

  @media $mqMobile {
    width: 100%;
    min-width: $mobile;
    min-height: 100vh;

    &_header_fixed {
      padding: 54px 20px 0;
    }
  }
}
