@import '../../styles/variables.styl';

.questions {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background: linear-gradient(90deg, #36385D 0%, #1D1A36 100%);

  &__text {
    padding: 35px 50px;
    width: 690px;
  }

  &__button {
    width: 220px;
  }

  &__title {
    color: $white;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
  }

  &__desc {
    color: $white;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.7;
  }

  @media $mqTablet {
    margin-top: 50px;
    margin-bottom: 50px;

    &__text {
      padding: 35px;
      width: calc(100% - 255px);
      text-align: left;
    }
  }

  @media $mqMobile {
    margin: 60px 0 0 0;
    padding: 33px 20px 50px 20px;
    flex-direction: column;
    border-radius: 12px;

    &__text {
      padding: 0;
      width: 100%;
    }

    &__title {
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 133%;
    }

    &__button {
      padding-top: 28px;
      width: 100%;
      max-width: 240px;
    }
  }
}
