@import '../../styles/variables.styl';

.phone {
  &__city {
    position: relative;
    display: inline-block;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      width: auto;
      height: 3px;
      background: $red;
    }
  }

  &__number,
  &__email {
    & a {
      text-decoration: none;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $red;
      }
    }
  }

  &__label {
    display: block;
    color: $white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
  }

  &_type_header {
    display: inline-block;
    vertical-align: middle;

    & .phone__city {
      margin-bottom: 15px;
      color: $black;
      font-size: 16px;
    }

    & .phone__number {
      & a {
        display: block;
        color: $black;
        font-size: 16px;

        &:hover {
          color: $red;
        }
      }
    }

    & .phone__email {
      display: none;
    }

    & .phone__label {
      display: none;
    }
  }

  &_type_burger {
    padding: 49px 40px 57px;
    text-align: center;

    & .phone__city {
      margin-bottom: 28px;
      color: $white;
      font-size: 18px;
      text-align: center;
    }

    & .phone__number {
      margin-bottom: 17px;
    }

    & .phone__number,
    & .phone__email {
      & a {
        display: inline-block;
        color: $white;
        font-size: 18px;
        line-height: 18px;

        &:hover {
          color: $red;
        }
      }
    }
  }

  &_type_footer {
    & .phone__city {
      margin-bottom: 28px;
      color: $white;
      font-size: 18px;
      text-align: center;
    }

    & .phone__number {
      margin-bottom: 17px;
    }

    & .phone__number,
    & .phone__email {
      & a {
        display: inline-block;
        color: $white;
        font-size: 18px;
        line-height: 18px;
        font-weight: 300;

        &:hover {
          color: $red;
        }
      }
    }
  }

  @media $mqTablet {
    &_type_header {
      display: none;
    }
  }

  @media $mqMobile {
    &_type_footer {
      padding: 0;
      text-align: center;
    }
  }
}
