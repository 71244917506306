@import '../../styles/variables.styl';

.copyright {
  opacity: .5;
  font-size: 14px;
  color: $white;
  font-weight: 300;
  line-height: 21px;

  & span {
    position: relative;
    padding-left: 14px;

    &:before {
      content: '©';
      position: absolute;
      top: -2px;
      left: 0;
      font-size: 10px;
    }
  }

  @media $mqMobile {
    padding-top: 38px;
    text-align: center;
  }
}
