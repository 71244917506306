@import '../../styles/variables.styl';

.text {
  &__page {
    & .title {
      width: 100%;
    }

    & p {
      padding-bottom: 25px;
      font-family: $fonts;
      font-size: 18px;
      line-height: 23px;
    }

    & ul {
      margin-top: -15px;
      margin-left: 20px;
      font-family: $fonts;
      font-size: 18px;
      line-height: 23px;
      list-style: circle;

      & li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
