@import '../../styles/variables.styl';

.contacts {
  &__intro {
    position: relative;

    & .intro-svg {
      position: absolute;
      top: 25px;
      right: 45px;
      width: 361px;
      height: 186px;
    }
  }

  &__page {
    & .text {
      padding-top: 22px;
      padding-bottom: 88px;
    }

    & h3 {
      padding-bottom: 45px;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
    }

    & p {
      font-size: 18px;
      line-height: 200%;
    }
  }

  &__request {
    & .request {
      margin: 0 auto;
      padding: 44px 40px 50px 40px;
      width: 510px;
      background: $white;
      border-radius: 12px;
    }
  }

  &__map {
    margin-bottom: 100px;
    height: 400px;
  }

  &__text {
    padding-top: 54px;
    padding-bottom: 54px;
  }

  @media $mqTablet {
    &__intro {
      & .intro-svg {
        top: 25px;
        right: 0;
      }
    }

    &__text {
      padding-top: 9px;
    }

    &__map {
      margin-bottom: 54px;
      height: 320px;
    }
  }

  @media $mqMobile {
    &__intro {
      & .intro-svg {
        top: 62px;
        right: 0;
        width: 201px;
        height: 104px;
      }
    }

    &__page {
      & .text {
        padding-top: 67px;
        padding-bottom: 93px;
      }

      & h3 {
        padding-bottom: 29px;
        text-align: left;
        font-size: 20px;
      }

      & p {
        padding-bottom: 13px;
        font-size: 16px;
        line-height: 120%;
      }

      & .request {
        padding: 30px 20px;
        width: 100%;
      }
    }

    &__text {
      padding-top: 27px;
      padding-bottom: 27px;
    }

    &__map {
      margin-left: -20px;
      margin-bottom: 60px;
      width: calc(100% + 40px);
      height: 260px;
    }
  }
}
