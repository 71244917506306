@import '../../styles/variables.styl';

.advantages {
  position: relative;
  margin-top: 120px;
  padding-top: 60px;
  padding-bottom: 32px;

  &__content {
    padding-top: 61px;
  }

  &__item {
    margin-right: 30px;
    width: 217px;
    height: 280px;
    vertical-align: top;
    display: inline-block;

    &:last-child {
      margin: 0;
    }
  }

  @media $mqTablet {
    padding-top: 25px;
    padding-bottom: 25px;

    &__content {
      padding-top: 37px;
      text-align: center;
    }

    &__item {
      margin: 10px;
      width: 300px;
      height: auto;
      display: inline-block;

      &:last-child {
        margin: 10px;
      }
    }
  }

  @media $mqMobile {
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 50px;

    &__content {
      padding-top: 39px;
    }

    &__item {
      margin-bottom: 40px;
      width: 100%;
      height: auto;
      display: inline-block;
      margin-right: 0;
    }
  }
}
